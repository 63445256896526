body {
	font-size: 16px;
	font-family: Helvetica, "Helvetica", sans-serif, -system-ui,-apple-system,BlinkMacSystemFont,segoe ui,Roboto,Helvetica,Arial,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol;
}

h1 { 
	font-size: 28px;
	line-height: 1.4;
	margin: 0.8rem 0;
	font-weight: 500;
}

.contain {
	width: 780px;
	max-width: 100%;
	padding: 30px 15px;
}

.center-block {
	display: block;
	margin: 0 auto;
}

.text-center {
	text-align: center;
}

.btn {
	display: inline-block;
	font-size: 24px;
	text-align: center;
	text-decoration: none;
	line-height: 1;
    padding: 0.8rem 1.5rem;
    border-radius: 3px;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 700;
}

.lead-emphasis {
	text-transform: uppercase;
	font-weight: 800;
	color: #072133;
	animation: 0.8s ease-out 0s 1 normal lead-in forwards;
}

.rlg-video { 
	width: 320px; 
	height: 180px;
}

.my-md {
	margin-top: 20px;
	margin-bottom: 20px;
}

.my-lg {
	margin-top: 35px;
	margin-bottom: 35px;
}

@media(min-width: 360px) {
	.rlg-video { 
		width: 360px; 
		height: 202px;
	}
}
@media(min-width: 420px) {
	.rlg-video { 
		width: 420px; 
		height: 236px;
	}
}
@media(min-width: 600px) {
	.rlg-video { 
		width: 600px; 
		height: 337px;
	}
	.my-md {
		margin-top: 30px;
		margin-bottom: 30px;
	}
	.my-md {
		margin-top: 45px;
		margin-bottom: 45px;
	}
}

.white-text { color: #FFF; }
.dark-blue-text { color: #072133; }
.dark-blue-bg {  background-color: #072133; }
.light-blue-text { color: #1fb8f0; }
.light-blue-bg { background-color: #1fb8f0; }
.light-orange-border { border: 2px solid #f6ae55; }
.orange-text { color: #f29727; }
.orange-bg { background-color: #f29727; }
.red-text { color: #d13135; }
.red-bg { background-color: #d13135; }

.enter-animation {
	animation: 0.8s ease-in-out 0s 1 normal entrance forwards;
}

@keyframes entrance {
	0% {
		transform: translateX(200%) skew(0deg, 3deg) rotateY(25deg);
	}
	85% {
		transform: translateX(0%) skew(-1deg) rotateY(-5deg);
	}
	100% {
		transform: translateX(0%) skew(0deg) rotateY(0deg);
	}
}

@keyframes  scale-up {
	0% {
		transform: scale(0);
	}
	90% {
		transform: scale(1.05);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes lead-in{
	0% {
		color: #072133;
	}
	10% {
		color: #1fb8f0;
	}
	20% {
		color: #072133;
	}
	30% {
		color: #1fb8f0;
	}
	40% {
		color: #072133;
	}
	50% {
		color: #1fb8f0;
	}
	60% {
		color: #072133;
	}
	70% {
		color: #1fb8f0;
	}
	80% {
		color: #072133;
	}
	90% {
		color: #1fb8f0;
	}
	100% {
		color: #072133;
	}
}